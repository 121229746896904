import { API_URL_CREATE_LIVE_RIDE, API_URL_LIST_SCHEDULED_RIDE, API_URL_STATS_LIVE, API_URL_RIDE_DETAILS, API_URL_SLOTS_STATS, API_URL_DOWNLOAD_RECEIPT, 
  API_URL_RIDE_DETAILS_FOR_OTP, API_URL_CONFIRM_RIDE_FOR_OTP, API_URL_PROVISIONAL_AMT, API_URL_PICKUP_NOTES_HISTORY, API_URL_CONNECT_TO_DRIVER,API_URL_PAST_RIDE_STATS_ZONE,
  API_URL_GET_DISPATCHER, API_URL_SLOTS_STATS_V2,API_URL_PASTRIDE_CSV, API_URL_CONNECT_TO_DRIVER_BY_ID, API_URL_EXTRA_SLOTS_STATS,API_URL_PREMIUM_SLOTS, API_URL_LIST_LIVE_RIDE_BETA,
  API_URL_GET_SUB_PLACE,API_URL_GET_PACKAGE,API_URL_SEND_NOTIFICATION, API_URL_CUSTOM_SEARCH, API_URL_MARK_CUSTOMER_ARRIVED,API_URL_GET_ESTIMATE_FARE, API_URL_SAVE_ELIGIBLE_TIMESTAMP, 
  API_URL_GET_ELIGIBLE_TIMESTAMPS, API_RIDE_DEALY, API_STATUS_PAYMENT, API_EDC_DEVICES, API_URL_GET_ESTIMATE_REFUND, API_REQUEST_PAYMENT, API_URL_STATS_LIVE_V3, API_DRIVER_CTA_MARK,
  API_HARD_BLOCK_UPDATE,API_URL_STATS_LIVE_V4, API_URL_SLOTS_STATS_NEW ,API_HIGH_DELAY_REGIONS,API_URL_LIST_LIVE_RIDE_BETA_NEW,
  API_URL_CONNECT_SOS_TO_DRIVER} from './../../config/apiRouteConfig/index';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { API_URL_LIST_LIVE_RIDE, API_URL_PAST_RIDE_LIST, API_URL_ACTION_LIVE_RIDE, API_URL_PASTRIDE_CSV_DOWNLOAD,API_URL_RIDE_DELAY_ALERT,API_URL_RIDE_DELAY_ALERT_STATUS,API_DUMMY_RIDES,API_DUMMY_RIDES_COUNT,API_URL_GET_NOTIFICATION_CONTENT,API_URL_SCHEDULED_RIDE_STAT,API_URL_SCHEDULED_RIDE_SLOT} from '../../config/apiRouteConfig';

@Injectable({
  providedIn: 'root'
})
export class RideService {


  constructor(private _http: HttpClient) {

  }
  downloadFile(start, end): Observable<Blob> {
    return this._http.get(API_URL_PASTRIDE_CSV_DOWNLOAD + '?startTime=' + start + '&endTime=' + end,
      { responseType: 'blob' });
  }

  markCustomerArrived(rideReqId): Observable<any>{
    return this._http.post(`${API_URL_MARK_CUSTOMER_ARRIVED}` + "?rideReqId=" + rideReqId, null);
  }

  actionOnThisRide(action, rideId, driverId = null, data = null, latitude = null, longitude = null, locationName = null,reassignmentReason=null,cancellationReason=null): Observable<any> {
    //console.log(action, rideId);
    var post: any = {
      action,
      rideId,
    };
    if (data != null) {
      Object.keys(data).forEach(key => {
        post[key] = data[key]
      })
    }
    if (driverId) {

      post.driverId = driverId;
    }
    if (latitude && longitude && locationName) {
      post.latitude = latitude;
      post.longitude = longitude;
      post.locationName = locationName;
    }
    if(reassignmentReason)
       post.reAssignmentReason = reassignmentReason
    //console.log(post)
    if(cancellationReason)
       post.cancellationReason = cancellationReason
    //console.log(post)
    return this._http.post(`${API_URL_ACTION_LIVE_RIDE}/${rideId}/actions`, post);
  }

  async getLiveRides(type): Promise<any> {
    let url = `${API_URL_LIST_LIVE_RIDE}`
    if (type)
      url += '?ride_type=' + type
    return await this._http.get(url).toPromise().then((res) => {
      return res['response'];
    });;
  }

  async getLiveRidesBeta(type,filter,body): Promise<any> {
    let url = `${API_URL_LIST_LIVE_RIDE_BETA}`
    if (type)
      url += '?ride_type=' + type
    if(filter && filter!= "")
      url += '?filter=' + filter
    return await this._http.post(url,body).toPromise().then((res) => {
      return res['response'];
    });;
  }
 async getLiveRidesBetaNew(userType,rideType,rideState,misc): Promise<any>{
  
    let url = `${API_URL_LIST_LIVE_RIDE_BETA_NEW}`

    const body = {
      isFilterSelected: (rideType.length== 0 && rideState.length==0 && misc.length == 0)?false:true,
      userTypeFilter: userType,
      rideStatesFilter: rideState,
      rideTypesFilter: rideType == null?[]:rideType,
      miscFilters: misc
    };
    if(userType == "ELITE" ||userType == "NON_ELITE" )
      body.isFilterSelected = true

    return await this._http.post(url, body).toPromise().then((res) => {
      return res['response'];
    });;;
  }

  getPastRides(start, end, riderId?:string, phone?:string,email?:string,firstName?:string,lastName?:string,vehicleNo?:string,partyReferenceNumber?:string,rideState?:string,seek?:string,count?:string): Observable<any> {
    return this._http.get(`${API_URL_PAST_RIDE_LIST}` + '?startTime=' + start + '&endTime=' + end   +riderId  + phone + email +  firstName + lastName + vehicleNo + partyReferenceNumber+rideState+seek+count);
  }

  getPastRidesByDto(start, end, pastRideSearchDto,seek?:string,count?:string): Observable<any>{
    return this._http.post(`${API_URL_PAST_RIDE_LIST}` + '?startTime=' + start + '&endTime=' +end+seek+count,pastRideSearchDto);
  }
  getPastRidesByDtoFilter(start, end, pastRideSearchDto,seek?:string,count?:string): Observable<any>{
    return this._http.post(`${API_URL_PAST_RIDE_LIST}` + '/filter?startTime=' + start + '&endTime=' +end+seek+count,pastRideSearchDto);
  }

  saveEligibleTimestamp(eligibleTimestampDto): Observable<any>{
    return this._http.post(`${API_URL_SAVE_ELIGIBLE_TIMESTAMP}`,eligibleTimestampDto);
  }

  getEligibleTimestamp(){
    return this._http.get(`${API_URL_GET_ELIGIBLE_TIMESTAMPS}`);
  }

  getPastRidesStats(start, end, riderId?:string, phone?:string,email?:string,firstName?:string,lastName?:string,vehicleNo?:string,partyReferenceNumber?:string,rideState?:string): Observable<any> {
    if(start >=1706682600000 && (riderId== undefined && phone == undefined || riderId =="" && phone =="")){
      return this._http.get(`${API_URL_PAST_RIDE_STATS_ZONE}` + '/V3?startTime=' + start + '&endTime=' + end   +riderId  + phone + email +  firstName + lastName + vehicleNo + partyReferenceNumber+rideState);
    }

    return this._http.get(`${API_URL_PAST_RIDE_STATS_ZONE}` + '?startTime=' + start + '&endTime=' + end   +riderId  + phone + email +  firstName + lastName + vehicleNo + partyReferenceNumber+rideState);
  }



  getScheduledRides(type,field,parameter,seek,count): Observable<any> {
    let url = `${API_URL_LIST_SCHEDULED_RIDE}`
    if (type)
      url += '?ride_type=' + type
    if(field)
      url+="?"+field+"="+parameter
    else
      url+='?seek='+seek+'&count='+count

    return this._http.get(url);
  }
  getMapData(from, to) {
    // tslint:disable-next-line: max-line-length
    return this._http.get(`https://maps.googleapis.com/maps/api/directions/json?origin=${from.lat},${from.lng}&destination=${to.lat},${to.lng}&key=AIzaSyAHaaYJ39pVWpqbqsoq2Jh7Q7NSgbL0rsQ`)
  }

  createLiveRide(data) {
    return this._http.post(API_URL_CREATE_LIVE_RIDE, data);
  }

  getOnlineDriverStats() {
    return this._http.get(API_URL_STATS_LIVE_V3)
  }
  getOnlineDriverStatsNew(type) {
    return this._http.get(API_URL_STATS_LIVE_V4 + "?vehicleCategory=" + type)
  }
  getSlotStatsNew(type, date, region) {
    let url = region ? API_URL_SLOTS_STATS_NEW+"?type="+type+"&date="+date + "&regionName=" + region : API_URL_SLOTS_STATS_NEW+"?type="+type+"&date="+date
    return this._http.get(url)
  }
  getSlotStats(type, date) {
    return this._http.get(API_URL_SLOTS_STATS+"?type="+type+"&date="+date)
  }

  getExtraSlotStats(date, region, rideType, selectedRegionType, selectedSlotMiscCategory) {
    let url ="";
    if(region) 
      url = "&region="+region;
    if(rideType) 
      url = url + "&rideType="+rideType;
    if(selectedRegionType)
      url = url + "&slotRegion="+ selectedRegionType;
    if(selectedSlotMiscCategory)
      url = url + "&miscSlotCategory="+  selectedSlotMiscCategory;
    return this._http.get(API_URL_EXTRA_SLOTS_STATS+"?date="+date+url)
  }

  getSlotStatsV2() {
    return this._http.get(API_URL_SLOTS_STATS_V2)
  }


  getDetailsForRideRequestId(rideRequestId) {
    return this._http.get(API_URL_RIDE_DETAILS + "/" + rideRequestId)
  }


  getDetailsForOtp(otp) {
    return this._http.get(API_URL_RIDE_DETAILS_FOR_OTP.replace(':otp', otp))
  }

  confirmRideForOtp(otp) {
    return this._http.post(API_URL_CONFIRM_RIDE_FOR_OTP.replace(':otp', otp), {})
  }

  downloadReceipt(rideId): Observable<Blob> {
    return this._http.post(API_URL_DOWNLOAD_RECEIPT + "?rideId=" + rideId, rideId, { responseType: 'blob' })
  }

  getProvisionalAmount(data) {
    var post: any = {};
    if (data != null) {
      Object.keys(data).forEach(key => {
        post[key] = data[key]
      })
    }
    return this._http.post(`${API_URL_PROVISIONAL_AMT}`, post);
  }

  getPickupNotesHistory(rideRequestId: any):Observable<any> {
    return this._http.get(`${API_URL_PICKUP_NOTES_HISTORY}`+"?rideRequestId="+rideRequestId);
  }

  callConnectToDriver(dispatcherNo,rideData, isOnRide): Observable<any>{
    let ssoId = localStorage.getItem('ssoId');
    console.log(typeof rideData);
    console.log(typeof dispatcherNo);
    
    
    let url = isOnRide ? `${API_URL_CONNECT_TO_DRIVER}` + "?from=" +dispatcherNo +"&driverId=" + rideData :
      `${API_URL_CONNECT_TO_DRIVER_BY_ID}` + "?from=" +dispatcherNo +"&driverId=" + rideData + "&ssoId=" + ssoId;
    return this._http.post(url,{})
  }

  callConnectSOSToDriver(dispatcherNo,rideData, isOnRide){
     let ssoId = localStorage.getItem('ssoId');
    console.log(typeof rideData);
    console.log(typeof dispatcherNo);
    
    
    let url =  `${API_URL_CONNECT_SOS_TO_DRIVER}` + "?from=" +dispatcherNo +"&driverId=" + rideData;
    return this._http.post(url,{})
  }
  getDispatcherList(): Observable<any>{
    return this._http.get(`${API_URL_GET_DISPATCHER}`)
  }
  actionOnRideDelayAlert(data){
      return this._http.post(`${API_URL_RIDE_DELAY_ALERT}`, data);
  }
  getRideDelayAlertStatus(){
    return this._http.get(`${API_URL_RIDE_DELAY_ALERT_STATUS}`)
  }
  getDummyRides(start, end, riderId?:string, phone?:string,email?:string,firstName?:string,lastName?:string,vehicleNo?:string,seek?:string,count?:string): Observable<any> {
    return this._http.get(`${API_DUMMY_RIDES}` + '?startTime=' + start + '&endTime=' + end   +riderId  + phone + email +  firstName + lastName + vehicleNo + seek + count);
  }
  getPhantomRideStat(){
    return this._http.get(`${API_DUMMY_RIDES_COUNT}`)
  }
  getCancellationNotificationContent(reason,rideId){
    console.log("cancellation popup",reason,rideId)
      let url = `${API_URL_GET_NOTIFICATION_CONTENT}`
      if (reason && rideId)
        url += '?cancellationReason=' + reason + '&rideId=' + rideId
        console.log("url",url)
      return this._http.get(url);
    }
  getScheduledRideStat(){
    return this._http.get(`${API_URL_SCHEDULED_RIDE_STAT}`)
  }

  getScheduledRideSlots(){
    return this._http.get(`${API_URL_SCHEDULED_RIDE_SLOT}`)
  }
  getPremiumSlots(date,slotType?:any){
    return this._http.get(`${API_URL_PREMIUM_SLOTS}`+"?date="+date+"&type="+slotType);
  }
  getPackageCategories(packageCode,lat,lng){
    let latLong = ""
    if(lat){
      latLong="?pickupLat="+lat+"&pickupLong="+lng;
    }
    return this._http.get(`${API_URL_GET_PACKAGE}`+packageCode+`/category`+latLong);
  }
  sendNotificaiton(rides,template){
    let data = {"rideIDs" : rides}
    return this._http.post(`${API_URL_SEND_NOTIFICATION}`+"?template=RIDER_DISPATCH_"+template,data)
  }
  getDataForCustomSearch(searchBy, queryToSearch) : Promise<any>{
    var driversForCustomSearchUrl = `${API_URL_CUSTOM_SEARCH}` + searchBy + "/" + queryToSearch;
    return this._http.get(driversForCustomSearchUrl).toPromise();
  }

  getSubPlaces(pickUpLat,pickUpLng){

    return this._http.get(`${API_URL_GET_SUB_PLACE}`+"?pickUpLat="+pickUpLat+"&pickUpLng="+pickUpLng);
  }
  
  estimateFare(fare){
    return this._http.post(`${API_URL_GET_ESTIMATE_FARE}`,fare);
  }

  // getDelayRide(showDetailsFlag,serviceRegion?:any[],rideCat?:any[],vehicleCat?:any[],showEph2?:any){
  //   return this._http.get(`${API_RIDE_DEALY}`+"?showDetailsFlag="+showDetailsFlag+"&serviceRegion="+serviceRegion+"&rideCategory="+rideCat+"&vehicleCategory="+vehicleCat+"&eph2DetailsOnly="+showEph2);
  // }
  getDelayRide(showDetailsFlag,serviceRegion?:any[],rideCat?:any[],vehicleCat?:any[],showEph2?:any) {
    let url = `${API_RIDE_DEALY}?showDetailsFlag=${showDetailsFlag}&serviceRegion=${serviceRegion}&rideCategory=${rideCat}&vehicleCategory=${vehicleCat}`;
    
    if (showEph2 !== undefined) {
        url += `&eph2DetailsOnly=${showEph2}`;
    }
    
    return this._http.get(url);
}

  getHighDelayData(){
    return this._http.get(`${API_HIGH_DELAY_REGIONS}`)
  }
  // getDelayRideFilter(showDetailsFlag,serviceRegion,rideCat,vehicleCat){
  //   return this._http.get(`${API_RIDE_DEALY}`+"?showDetailsFlag="+showDetailsFlag+"&serviceRegion="+serviceRegion+"&rideCategory="+rideCat+"&vehicleCategory="+vehicleCat);
  // }
  estimateRefund(body){
    return this._http.post(`${API_URL_GET_ESTIMATE_REFUND}`,body)
  }
  requestPayment(body){
    return this._http.post(`${API_REQUEST_PAYMENT}`,body)
  }

  getStatusForPaytmEDC(body){
    console.log(body)
    return this._http.post(`${API_STATUS_PAYMENT}`,body)
  }
  getEDCMachines(){
    return this._http.get(`${API_EDC_DEVICES}`);
  }
  triggerPastRideReport(start, end,email){
    return this._http.get(API_URL_PASTRIDE_CSV + '?startTime=' + start + '&endTime=' + end+ '&email=' + email);
  }
  markDriverCTA(rideId,driverId){
    return this._http.post(`${API_DRIVER_CTA_MARK}`+'?rideId=' + rideId+'&driverId='+driverId,{})
  }

  updateHardblockSetting(body){
    return this._http.post(`${API_HARD_BLOCK_UPDATE}`,body)
  }
}
